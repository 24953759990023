import {Card} from "@mui/material";
import DataGrid, {DataGrigColumn} from "../../../shared/components/DataGrid";
import React from "react";
import {useContractorSearch} from "../api/useContractorSearch";
import {ContractorSearchPayloadInput, JointProfileOutput} from "../../../api/sdl";

interface Props {
  columns: DataGrigColumn<Partial<JointProfileOutput>>[],
  onRowClick?: (row: Partial<JointProfileOutput>) => void,
  filter: ContractorSearchPayloadInput,
}

export function ContractorSearchableList({columns, onRowClick, filter}: Props) {
  const x = useContractorSearch(filter);

  return <Card sx={{mt: 3, p: 0}}>
      <DataGrid columns={columns}
                rows={x.rows}
                rowsPerPage={x.rowsPerPage}
                page={x.page}
                total={x.total}
                error={x.error?.message}
                onRowClick={onRowClick}
                onNextClick={x.goNextPage}
                onPrevClick={x.goPreviousPage}
                loading={x.loading}
                isLastPage={x.isLastPage}
      />
    </Card>
}