import {EducationApiData} from "../../education-form/types";
import {Box, Button, Typography} from "@mui/material";
import {readableDegree} from "../../education-form/utils";
import {getMonthName} from "../../../../../feature";
import * as React from "react";

interface Props {
  education: EducationApiData,
  editable: boolean,
  onEditClick: () => void
}

export function EducationView({education: ed, editable, onEditClick}: Props) {

  return <Box sx={{display: "flex", justifyContent: 'space-between',}}>
    <Box>
      <Typography variant={"body1"} sx={{fontWeight: 600}} data-test={"education-school"}>{[ed.studyField, ed.school].filter(it => !!it).join(", ")}</Typography>
      <Typography variant={"body1"} data-test={"education-title"}>{readableDegree(ed.degree)}</Typography>
      <Typography sx={{color: '#6D6D6D'}}  data-test={"education-dates"}>
        <Dates ed={ed} />
      </Typography>
    </Box>
    {editable &&
      <Box>
        <Button onClick={onEditClick} data-test={"edit-education-item"}>Edit</Button>
      </Box>
    }
  </Box>
}

const Dates = ({ed}: {ed: EducationApiData}) => {
  if(!ed.startDateMonth && !ed.endDateMonth && !ed.startDateYear && !ed.endDateYear) {
    return null;
  }
  return <>{getMonthName(ed.startDateMonth!, 'short')} {ed.startDateYear} - {getMonthName(ed.endDateMonth!, 'short')} {ed.endDateYear}</>
}