import React, { PropsWithChildren} from "react";
import {
  PositionMatchOutput,
  PositionOutput,
  useContractorPositionMatchByPositionIdLazyQuery,
  usePositionByIdQuery
} from "../../../api/sdl";
import useCalendarAvailability from "../../../shared/hooks/useCalendarAvailability";


interface PositionMatchContextProviderProps {
  positionId: string,
  contractorId: string,
}

interface PositionMatchContextProviderValue {
  positionId: String,
  position?: PositionOutput,
  meetingSlotsAreAvailable?: boolean,
  scheduleUpdated: () => void,
  loading: boolean,
  positionMatch?: PositionMatchOutput | null,
}

const defaultValue: PositionMatchContextProviderValue = {
  positionId: 'N/A',
  position: undefined,
  scheduleUpdated: () => {},
  loading: true
};

export const PositionMatchContext = React.createContext<PositionMatchContextProviderValue>(defaultValue);

export const PositionMatchContextProvider = ({children, positionId, contractorId}: PropsWithChildren<PositionMatchContextProviderProps>) => {

  const [inc, setInc] = React.useState(0);
  const [loading, setLoading] = React.useState(defaultValue.loading);
  const [position, setPosition] = React.useState<PositionOutput>();
  const [positionMatch, setPositionMatch] = React.useState<PositionMatchOutput | null>();

  const [positionMatchRequest, {data: positionMatchData}] = useContractorPositionMatchByPositionIdLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {positionId: positionId},
    onCompleted: (res) => {
      setPositionMatch(res.contractorPositionMatchByPositionId as PositionMatchOutput || null)
    }
  });

  const {data: positionData, refetch} = usePositionByIdQuery({fetchPolicy: 'no-cache', variables: {id: positionId}, onCompleted: (data) => {
    if(data.positionById?.contractorAlreadyApplied === true) {
      return positionMatchRequest()
    } else {
      setPositionMatch(null)
    }
  }});

  const {meetingSlotsAreAvailable} = useCalendarAvailability({contractorId, companyId: positionData?.positionById?.company?.id, inc: inc});

  const scheduleUpdated =() => {
    console.log('scheduleUpdated CALLBACK');
    setInc(inc + 1);
  };

  React.useMemo(() => {

    if(positionData?.positionById !== undefined && meetingSlotsAreAvailable !== undefined && positionMatch !== undefined) {
      setLoading(false);
      setPosition(positionData!.positionById!);
    } else {
      console.log('positionData', positionData, 'meetingSlotsAreAvailable', meetingSlotsAreAvailable, 'positionMatch', positionMatch)
    }
  }, [positionData, meetingSlotsAreAvailable, positionMatch]);


  return <PositionMatchContext.Provider value={{
    positionId,
    position: position,
    meetingSlotsAreAvailable,
    scheduleUpdated,
    loading,
    positionMatch
  }}>
    {children}
  </PositionMatchContext.Provider>
};
