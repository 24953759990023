import {useCompanyByIdQuery, useUserProfileLazyQuery} from "../../../api/sdl";
import React from "react";


export function useCompanyApi(companyId: string) {

  const {data: companyData, loading} = useCompanyByIdQuery({
    fetchPolicy: 'no-cache',
    variables: {id: companyId}
  });

  const company = React.useMemo(() => {
    return companyData?.companyById;
  }, [companyData])

  const [getUserProfile, {data: userProfileData}] = useUserProfileLazyQuery({fetchPolicy: 'no-cache'});

  React.useEffect(() => {
    if (company?.companyProfile.id) {
      getUserProfile({variables: {userId: companyData?.companyById?.companyProfile.userId}})
    }
  }, [companyData]);

  const userProfile = React.useMemo(() => {
    return userProfileData?.userProfile
  }, [userProfileData]);

  return {company, loading, userProfile};
}