import {useMigrateResumeMutation} from "../../../api/sdl";
import {LoadingButton} from "@mui/lab";
import React from "react";

export function MigrateResume() {

  const [migrate, {loading}] = useMigrateResumeMutation();
  return <>
    <LoadingButton onClick={() => migrate()} loading={loading} variant={"contained"}>
      Migrate Resume
    </LoadingButton>
  </>
}