import React from 'react';
import {LastScheduledEmailsQuery, useLastScheduledEmailsQuery} from "../../../api/sdl";
import DataGrid, {DataGrigColumn} from "../../../shared/components/DataGrid";
import {DateTime} from "luxon";


type lastScheduledEmailsData = LastScheduledEmailsQuery["lastScheduledEmails"][0]
const columns: DataGrigColumn<Partial<lastScheduledEmailsData>>[] = [
  {
    title: 'Scheduled On',
    value: e => DateTime.fromISO(e.scheduledAt).toFormat('LL/dd/yyyy hh:mm a')
  },
  {
    title: "Recipient",
    value: e => e.recipient || "N/A"
  },
  {
    title: "template",
    value: e => e.templateName || "N/A",
  }
]

export function LastScheduledEmails() {


  const {data, loading,} = useLastScheduledEmailsQuery()

  console.log(data);
  return <>

    <DataGrid columns={columns}
              rowsPerPage={100}
              total={100}
              page={0}
              onPrevClick={() => {}}
              onNextClick={() => {}}
              rows={data?.lastScheduledEmails || []}
              loading={loading}
    />
  </>
}