import {EmploymentHistory, EmploymentHistoryPayloadInput} from "../../../api/sdl";
import * as _ from "lodash";

/**
 * It seems like it handled in Formik converter.
 *
 * @param value
 */
export function convertEmploymentHistory(value: EmploymentHistory) {

  const newValue = {
    ...value,
    location: value.location || '',
  }
  delete newValue.attributesExpanded
  delete newValue.__typename
  return newValue as EmploymentHistoryPayloadInput
}


export function getMonthName(monthNumber: number, type: any = 'long'): string {

  if(!monthNumber) {
    return '';
  }
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', { month: type });
}

export function monthsRange() {
  return _.range(1,13)
}

export const formatEmploymentHistoryDateRange = (employmentHistory: EmploymentHistory) => {

  const start = `${getMonthName(employmentHistory.startDateMonth!, 'short')} ${employmentHistory.startDateYear}`;
  const end =  employmentHistory.currentlyIsWorking ? 'now' : `${getMonthName(employmentHistory.endDateMonth!, 'short')} ${employmentHistory.endDateYear}`;
  return `${start} - ${end}`
}