import {Box, Button, Link, Typography} from "@mui/material";
import React from "react";
import {DataGrigColumn} from "../../shared/components/DataGrid";
import {
  ContractorSearchPayloadInput,
  ContractorStatus,
  JointProfileOutput,
} from "../../api/sdl";
import {ContractorSearchableList} from "./ui/ContractorSearchableList";
import ContractorFilter from "./ui/ContractorFilter";
import useSessionStorageState from "../../shared/hooks/useSessionStorageState";


interface Props {
  onInvite: (contractorId: string) => void,
  onContractorSelect: (contractorId: string) => void,
}

export function MatchContractorList({onInvite, onContractorSelect}: Props) {

  const [filter, setFilter] = useSessionStorageState<ContractorSearchPayloadInput>(
    'positionMatchFilter',
    {attributes: [], state: null, term: '', status: ContractorStatus.Approved}
  );

  const handleInvite = (id: string) => {
    onInvite(id)
  }

  const columns: DataGrigColumn<Partial<JointProfileOutput>>[] = [
    {
      title: 'Name',
      value: o =>
        <Link sx={{cursor: "pointer"}} onClick={() => onContractorSelect(o.contractorProfile?.contractorId)}>
          {o.profile?.firstName + ' ' + o.profile?.lastName}
        </Link>
    },
    {
      title: 'Email',
      value: o => o.profile?.email || '',
    },
    {
      title: 'Locations',
      value: o =>
        o.contractorProfile?.cities.map(it => `${it.city}, ${it.stateShort}`).join("; ") || ''
      ,
    },
    {
      title: 'Attributes',
      value: o => o.contractorProfile?.attributes?.map(attr => `${attr.attribute}`).join("; ") || "",
    },
    {
      title: 'Invite',
      value: o => <Button onClick={() => handleInvite(o.contractorProfile?.contractorId)}>Invite</Button>,
    },
  ]

  return <div>
    <Box sx={{
      display: 'flex',
      flexDirection: {xs: 'column', md: 'row'},
      alignItems: 'center',
      flex: 1,
      justifyContent: 'space-between',
      gap: 2
    }}>
      <Box sx={{display: 'flex'}}>
        <Typography variant={'h3'} sx={{ml: 2}}>Search contractors</Typography>
      </Box>
      <ContractorFilter filter={filter} onChange={setFilter} hideStatus/>
    </Box>
    <ContractorSearchableList columns={columns}
                              filter={filter}/>
  </div>
}

