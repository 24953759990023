import {Box, Typography} from "@mui/material";
import React from "react";
import {CandidateMatchApiData} from "../lib/types";

interface Props {
  candidateMatch?: CandidateMatchApiData
}

const formatLocation = (c: CandidateMatchApiData) => (
  c.contractorProfile.cities.map((city) => `${city.city}, ${city.stateShort}`).join("; ")
)

export function CandidateLocation({candidateMatch}: Props) {
  if (!candidateMatch || candidateMatch?.contractorProfile?.cities?.length == 0) {
    return null;
  }

  return <Box sx={{display: 'flex', gap: 0, alignItems: 'center'}}>
    <Box component={'img'} src={`/img/teenyicons/pin.svg`} width={15} height={15}/>
    <Typography variant={'body2'}
                sx={{ml: '6px', mr: '14px', whiteSpace: 'nowrap'}}>{formatLocation(candidateMatch)}</Typography>
  </Box>
}