import {
  Box, Button,
  Checkbox,
  FormControl, FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem, Select, TextField,
} from "@mui/material";
import React, {useContext} from "react";
import {
  ContractorAttribute
} from "../../../api/sdl";
import {EmploymentHistoryFormFields, schemaFields} from "../lib";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  ContractorAttributesContext, EmploymentTypesSelector,
  SpecialitiesAutocomplete,
  withContractorAttributes
} from "../../../entity";
import {useFormik} from "formik";
import * as yup from "yup";
import {ConfirmableDeleteButton} from "./ConfirmableDeleteButton";
import {getMonthName, monthsRange} from "../lib/util";

const Editor = CKEditor as any;

interface Props {
  employmentHistory: any,
  specialities: ContractorAttribute[],
  employmentTypes: ContractorAttribute[],
  onDelete: () => void,
  onFormSubmit: (value: any) => void,
}

const EmploymentHistoryForm = ({employmentHistory, employmentTypes, specialities, onDelete, onFormSubmit}: Props) => {

  const formik = useFormik<EmploymentHistoryFormFields>({
    initialValues: employmentHistory as any,
    validationSchema: yup.object(schemaFields),
    onSubmit: (values) => {
      onFormSubmit(values)
    },
    validateOnChange: false,
    enableReinitialize: true,
  });

  return <Box component={'form'} flex={4} onSubmit={formik.handleSubmit}>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, mb: 3, flex: 1, maxWidth: 500, mt: {xs: 3, md: 0}}}>
      <SpecialitiesAutocomplete onChange={v => formik.setFieldValue('specialities', v)}
                                value={formik.values.specialities}
                                error={formik.touched.specialities && formik.errors.specialities}
                                options={specialities}/>


      <FormControl>
        <FormLabel>Title</FormLabel>
        <TextField placeholder="Title"
               name="title"
               data-test="title"
               value={formik.values.title}
               onChange={formik.handleChange}
               error={formik.touched.title && Boolean(formik.errors.title)}
        />
        {(formik.touched.title && formik.errors.title) && <FormHelperText data-test="title-error" sx={{color: '#D3232F'}}>{formik.errors.title}</FormHelperText>}
      </FormControl>

      <EmploymentTypesSelector onChange={v => formik.setFieldValue('employmentTypes', Array.of(v))}
                               value={formik.values.employmentTypes}
                               error={formik.touched.employmentTypes && formik.errors.employmentTypes}
                               options={employmentTypes}/>

      <FormControl>
        <FormLabel>Company name</FormLabel>
        <TextField placeholder="Company name"
               name="companyName"
               data-test="company-name"
               value={formik.values.companyName}
               onChange={formik.handleChange}
               error={formik.touched.companyName && Boolean(formik.errors.companyName)}
        />
        {(formik.touched.companyName && formik.errors.companyName) && <FormHelperText data-test="company-error" sx={{color: '#D3232F'}}>{formik.errors.companyName}</FormHelperText>}
      </FormControl>

      <FormControl>
        <FormLabel>Location</FormLabel>
        <TextField placeholder="Location"
               name="location"
               data-test="location"
               value={formik.values.location}
               onChange={formik.handleChange}
               error={formik.touched.location && Boolean(formik.errors.location)}
        />
        {(formik.touched.location && formik.errors.location) && <FormHelperText data-test="location-error" sx={{color: '#D3232F'}}>{formik.errors.location}</FormHelperText>}
      </FormControl>

      <Box sx={{display: 'grid', gridTemplateColumns: {xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}, gap: 3, width: 1, alignItems: 'flex-end'}}>
        <FormControl sx={{ minWidth: 240 }} error={formik.touched.startDateMonth && Boolean(formik.errors.startDateMonth)}>
          <FormLabel>Start date</FormLabel>
          <Select data-test="start-date-month" value={formik.values.startDateMonth?.toString()} onChange={(e) => {
            if (e.target.value) {
              formik.setFieldValue('startDateMonth', e.target.value)
            }
          }}>
            {monthsRange().map((v, i) => <MenuItem key={'st' + v } value={v.toString()}>{getMonthName(v)}</MenuItem>)}
          </Select>
          {(formik.touched.startDateMonth && formik.errors.startDateMonth) && <FormHelperText data-test="start-month-errror" sx={{color: '#D3232F'}}>{formik.errors.startDateMonth}</FormHelperText>}
        </FormControl>
        <FormControl sx={{width: 1}}>
          <TextField placeholder="Year"
                 name="startDateYear"
                 data-test="start-date-year"
                 type={'number'}
                 value={formik.values.startDateYear}
                 onChange={formik.handleChange}
                 error={formik.touched.startDateYear && Boolean(formik.errors.startDateYear)}
          />
          {(formik.touched.startDateYear && formik.errors.startDateYear) && <FormHelperText data-test="start-year-error" sx={{color: '#D3232F'}}>{formik.errors.startDateYear}</FormHelperText>}
        </FormControl>
      </Box>
      <FormControlLabel control={(
        <Checkbox data-test="currently-is-working"
                  checked={formik.values.currentlyIsWorking}
                  onChange={(event) => formik.setFieldValue('currentlyIsWorking', event.target.checked)}
        />
      )} label="I am currently working in this role" />
      <Box sx={{display: formik.values.currentlyIsWorking ? 'none' : 'grid', gridTemplateColumns: {xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}, gap: 3, width: 1, alignItems: 'flex-end'}}>
        <FormControl sx={{ minWidth: 240 }} error={formik.touched.endDateMonth && Boolean(formik.errors.endDateMonth)}>
          <FormLabel>End date</FormLabel>
          <Select data-test="end-date-month" value={formik.values.endDateMonth?.toString()} onChange={(e) => {
            if (e.target.value) {
              formik.setFieldValue('endDateMonth', e.target.value)
            }
          }}>
            {monthsRange().map((v, i) => <MenuItem key={'st' + v } value={v.toString()}>{getMonthName(v)}</MenuItem>)}
          </Select>
          {(formik.touched.endDateMonth && formik.errors.endDateMonth) && <FormHelperText data-test="end-month-error" sx={{color: '#D3232F'}}>{formik.errors.endDateMonth}</FormHelperText>}
        </FormControl>
        <FormControl sx={{width: 1}}>
          <TextField placeholder="Year"
                 name="endDateYear"
                 data-test="end-date-year"
                 type={'number'}
                 value={formik.values.endDateYear}
                 onChange={formik.handleChange}
                 error={formik.touched.endDateYear && Boolean(formik.errors.endDateYear)}
          />
          {(formik.touched.endDateYear && formik.errors.endDateYear) && <FormHelperText data-test="end-year-error" sx={{color: '#D3232F'}}>{formik.errors.endDateYear}</FormHelperText>}
        </FormControl>
      </Box>

    </Box>
    <FormControl>
      <FormLabel>Description</FormLabel>
      <Editor
        editor={ ClassicEditor }
        config={{
          placeholder: "What were your responsibilities there? What achievements you are most proud of?",
          toolbar: ["undo", "redo", "|", "heading", "|", "bold", "italic", "|", "link", "|", "bulletedList", "numberedList", "outdent", "indent"]
        }}
        data={formik.values.description}
        data-test="presentation"
        onReady={ (editor: any) => {
          // You can store the "editor" and use when it is needed.
        } }
        onChange={ ( event: any, editor: any ) => {
          const data = editor.getData();
          formik.setFieldValue('description', data)
        } }
      />
      {(formik.touched.description && formik.errors.description) && <FormHelperText data-test="description-error" sx={{color: '#D3232F'}}>{formik.errors.description}</FormHelperText>}
    </FormControl>
    <Box sx={{my:2, display: 'flex'}}>
      <Box sx={{width: 1}}>
        {!!employmentHistory?.id &&
          <ConfirmableDeleteButton onConfirm={() => onDelete()} title={'Are you sure you want to delete the employment history?'}/>
        }
      </Box>
      <Box sx={{width: 1, textAlign: "right"}}>
        <Button variant="contained"  data-test="submit-form" onClick={() => {formik.submitForm()}}>Submit</Button>
      </Box>
    </Box>
  </Box>;
}

export default withContractorAttributes(function(props: Omit<Props, 'specialities' | 'employmentTypes'>) {
  const attributes = useContext(ContractorAttributesContext);
  return <EmploymentHistoryForm {...attributes} {...props} />
})
