import {Alert, Box, Typography} from "@mui/material";
import React from "react";
import {DateTime} from "luxon";

export function SlotAvailabilityAlert() {
  const firstRange = `${DateTime.now().plus({days: 0}).toFormat("LLL dd")} and ${DateTime.now().plus({days: 6} ).toFormat("LLL dd")}`;
  const secondRange = `${DateTime.now().plus({day: 8}).toFormat("LLL dd")} and ${DateTime.now().plus({days: 14} ).toFormat("LLL dd")}`;

  return <Alert sx={{my: 2, fontWeight: 500, fontSize: 14}} variant={'standard'} color={'error'}>
    <Box>In order to accept, you need to update your calendar to show your availability for an interview.</Box>
    <Box sx={{pt: 1}}>
      Please add available time slots for at least <Typography fontWeight={600} sx={{textDecoration: 'underline'}} display="inline">three different days</Typography> between {firstRange}, <br/>
      and time slots for at least <Typography fontWeight={600} sx={{textDecoration: 'underline'}} display="inline">three different days</Typography> between {secondRange}.
    </Box>
  </Alert>;
}