import {PositionOutput, PositionStatus, useAdminActivePositionsQuery} from "../../api/sdl";
import {Card, Chip} from "@mui/material";
import DataGrid, {DataGrigColumn} from "../../shared/components/DataGrid";
import React, {useMemo} from "react";
import * as _ from "lodash";
import {useHistory} from "react-router-dom";
import {DateTime} from "luxon";

const columns: DataGrigColumn<Partial<PositionOutput>>[] = [
  {
    title: 'Company',
    value: o => o.company?.name || ''
  }, {
    title: 'Title',
    value: o => o.title || ''
  }, {
    title: 'Status',
    value: o =>
      <Chip size={'small'}
            label={_.startCase(o.status?.toLowerCase())}
            sx={{background: [PositionStatus.Open, PositionStatus.Closed, PositionStatus.Deleted].includes(o.status as any) ? '#21bf5b' : '#3075b8'}}/>,
  }, {
    title: 'Created at',
    value: o => o.createdAt ? DateTime.fromISO(o.createdAt).toFormat('LL/dd/yyyy') : "N/A"
  },
  {
    title: 'Commission',
    value: o => <>{o.platformCommission}%</>,
  }];

export function PositionListActive() {

  const history = useHistory();
  const {data, loading} = useAdminActivePositionsQuery();

  const rows = useMemo(() => {
    return data?.adminActivePositions || [];
  }, [data]);
  return <>
    <h1>Draft and open positions</h1>
    <Card sx={{mt: 1, p: 0}}>
      <DataGrid columns={columns}
                rows={rows}
                rowsPerPage={1000}
                page={0}
                hidePaginator
                onRowClick={row => history.push(`/company/${row?.company?.id}/position/` + row.id)}
                onNextClick={() => {
                }}
                onPrevClick={() => {
                }}
                loading={loading}
                isLastPage={true}
      />
    </Card>
  </>
}