import {Box, FormControl, FormLabel, MenuItem, Select, Typography,} from "@mui/material";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {
  ContractorPaymentOutput,
  ContractorPaymentStatus,
  useAdminContractPaymentsQuery,
  useChangePaymentsStatusMutation,
} from "../../../../../api/sdl";
import DataGrid, {DataGrigColumn} from "../../../../../shared/components/DataGrid";
import {DateTime} from "luxon";
import {toCurrency, toFirstCase} from "../../../../../shared/utils";
import {useSnackbar} from "notistack";
import {useConfirm} from "../../../../../shared/ConfirmDialogContext";


const getColumns: (onPaymentClick: (payment: ContractorPaymentOutput) => void) => DataGrigColumn<ContractorPaymentOutput>[] = (onPaymentClick) => [
  {
    title: 'Date',
    value: o => <Typography variant={'body2'}>
      {DateTime.fromISO(o.invoiceDate).toFormat('LL/dd/yyyy')}
    </Typography>
  }, {
    title: 'Description',
    value: o => o.description || ''
  }, {
    title: 'Type',
    value: o => toFirstCase(o.type),
  }, {
    title: 'Amount',
    value: o => toCurrency(o.amount, 2, 2)
  }, {
    title: 'Payout Amount',
    value: o => toCurrency(o.payoutAmount, 2, 2)
  }, {
    title: 'InvoiceId',
    value: o => (
      <Box sx={{textDecoration: 'underline', fontSize: 14, color: '#000', cursor: 'pointer'}}
           onClick={() => onPaymentClick(o)}>
        {o.harvestInvoiceId}
      </Box>
    )
  }, {
    title: 'Status',
    value: o => toFirstCase(o.status),
  }]

interface ContractFormProps {
  contractId: string,
}

function ContractPayments({contractId}: ContractFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const {loading, data, called, refetch} = useAdminContractPaymentsQuery({
    variables: {workContractId: contractId, page: {page, size: rowsPerPage}}
  });

  const [changePaymentsStatus] = useChangePaymentsStatusMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      enqueueSnackbar('Status was changed successfully', {variant: 'info'});
    },
  });

  const payments = React.useMemo(() => {
    return data?.adminContractPayments.data || [];
  }, [data])

  const total = useMemo(() => {
    return data?.adminContractPayments.totalElements || 0;
  }, [data?.adminContractPayments.totalElements]);

  const onInvoiceClick = useCallback((payment: ContractorPaymentOutput) => (
    confirm({
      content: props => (
        <ContractorPaymentStatusSelect onChange={ props.onValueChange}
                                       defaultValue={payment.status}/>
      ),
      title: 'Select new payment status',
      okButtonTitle: 'Save',
      cancelButtonTitle: 'Close',
      width: 500
    })
      .then(val => {
        if (typeof val === "string") {
          return changePaymentsStatus({
            variables: {
              invoiceId: payment.harvestInvoiceId,
              workContractId: contractId,
              status: val as ContractorPaymentStatus
            }
          })
        }
      })
      .then(() => refetch())
  ), [contractId]);

  const columns = useMemo(() => {
    return getColumns(onInvoiceClick);
  }, [onInvoiceClick, getColumns]);

  return <DataGrid columns={columns}
                   rows={payments}
                   rowsPerPage={rowsPerPage}
                   page={page}
                   total={total}
                   onNextClick={() => setPage(p => p + 1)}
                   onPrevClick={() => setPage(p => p - 1)}
                   isLastPage={total <= rowsPerPage * (page + 1)}
                   loading={loading || !called}
  />
}

export default ContractPayments

const ContractorPaymentStatusSelect = ({defaultValue, onChange}: {defaultValue?: ContractorPaymentStatus, onChange: (value: ContractorPaymentStatus) => void}) => <FormControl sx={{width: 1}}>
  <FormLabel >
    Payment Status
  </FormLabel>
  <Select size={'small'}
          defaultValue={defaultValue}
          onChange={(e) => onChange(e.target.value as ContractorPaymentStatus)}
  >
    <MenuItem value={ContractorPaymentStatus.InvoiceDrafted}>{toFirstCase(ContractorPaymentStatus.InvoiceDrafted)}</MenuItem>
    <MenuItem value={ContractorPaymentStatus.InvoiceSent}>{toFirstCase(ContractorPaymentStatus.InvoiceSent)}</MenuItem>
    <MenuItem value={ContractorPaymentStatus.PaymentReceivedFromCustomer}>{toFirstCase(ContractorPaymentStatus.PaymentReceivedFromCustomer)}</MenuItem>
    <MenuItem value={ContractorPaymentStatus.PaidToContractor}>{toFirstCase(ContractorPaymentStatus.PaidToContractor)}</MenuItem>
    <MenuItem value={ContractorPaymentStatus.Error}>{toFirstCase(ContractorPaymentStatus.Error)}</MenuItem>
  </Select>
</FormControl>
