import {PositionMatchStatus} from "../../../api/sdl";
import {Typography} from "@mui/material";

interface Props {
  status?: PositionMatchStatus | null
}
export function ReadableCandidateMatchStatus({status}: Props) {

  let replacedStatus = status + "";
  switch (status) {
    case PositionMatchStatus.AddedToPosition:
      replacedStatus = "Waiting for the Contractor's approval";
      break;
    case PositionMatchStatus.CompanyDeclined:
      replacedStatus = "Company had declined";
      break;
    case PositionMatchStatus.ContractorAppliedToPosition:
      replacedStatus =  "Waiting Admin approval";
      break;
    case PositionMatchStatus.CompanyScheduledCall:
      replacedStatus = "Company is interviewing";
      break;

    case PositionMatchStatus.ContractorConfirmed:
      replacedStatus = "Company is reviewing";
      break;
  }

  return <>
    <Typography fontWeight={600}>{replacedStatus}</Typography>
  </>
}