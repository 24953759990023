import {EducationDegree, EducationPayloadInput} from "../../../../api/sdl";
import {EducationApiData, EducationFormFields} from "./types";

export function readableDegree(key: EducationDegree) {

  switch (key) {
    case 'ASSOCIATE_DEGREE':
      return "Associate Degree";
    case 'BACHELOR_DEGREE':
      return "Bachelor's Degree";
    case 'CERTIFICATE_DIPLOMA_PROGRAM':
      return "Certificate/Diploma Program";
    case 'DOCTORAL_DEGREE':
      return "Doctoral Degree (PhD)";
    case 'DOCTOR_MEDICINE':
      return "Doctor of Medicine (MD)";
    case 'MASTER_DEGREE':
      return "Master's Degree";
  }

}

export function toFormFields(apiData: EducationApiData): EducationFormFields {

  return {
    degree: apiData.degree,
    endDateMonth: apiData.endDateMonth?.toString() || "",
    endDateYear: apiData.endDateYear?.toString() || "",
    school: apiData.school,
    startDateMonth: apiData.startDateMonth?.toString() || "",
    startDateYear: apiData.startDateYear?.toString() || "",
    studyField: apiData.studyField?.toString() || "",
  }
}

export function toApiPayload(fields: EducationFormFields, id: string | undefined) {
  const payload = {
    degree: fields.degree,
    endDateMonth: intToApi(fields.endDateMonth),
    endDateYear: intToApi(fields.endDateYear),
    school: (fields.school),
    startDateMonth: intToApi(fields.startDateMonth),
    startDateYear: intToApi(fields.startDateYear),
    studyField: stringToApi(fields.studyField),
  } as EducationPayloadInput

  if(id) {payload.id = id}

  return payload
}

function intToApi(formValue: string): number | undefined {
  return formValue !== "" ? parseInt(formValue) : undefined;
}

function stringToApi(formValue: string) {
  return formValue !== "" ? formValue : undefined;
}