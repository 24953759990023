import {Backdrop, Box, Dialog, DialogContent, DialogTitle} from "@mui/material";

import Loading from "../../../../shared/components/Loading";
import React, {useEffect} from "react";
import {useEducationLazyQuery,} from "../../../../api/sdl";
import {EducationFormFields} from "../education-form/types";
import {toFormFields} from "../education-form/utils";
import {EducationForm} from "../education-form/EducationForm";
import {CrossIconButton} from "../../../../feature";
import {useIsMobile} from "../../../../shared";

interface Props {
  educationId?: string,
  open: boolean,
  onClose: () => void,
  onDelete: () => void,
  onFormSubmit: (value: EducationFormFields) => void,
}

const initialValues = {
    degree: '',
    school: '',
    studyField: "",
    startDateMonth: '',
    startDateYear: '',
    endDateMonth: '',
    endDateYear: '',
} as EducationFormFields;

export function EducationEditorModal(props: Props) {

  const isMobile = useIsMobile();
  const [requestData, {data, loading}] = useEducationLazyQuery({fetchPolicy: "no-cache"})

  const [initialFormValue, setInitialFormValue] = React.useState<EducationFormFields>();

  useEffect(() => {
    if(props.educationId) {
      requestData({variables: {educationId: props.educationId}})
        .then((res) => {
          setInitialFormValue(toFormFields(res.data!.education))
        })
    } else {
      setInitialFormValue(initialValues)
    }
  }, [props.educationId]);


  return <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={props.open}
    onClick={() => {}}
  >
    <Dialog open={props.open} fullScreen={isMobile}>
      <DialogTitle>
        <Box sx={{position: 'absolute', right: 20, zIndex: "10000"}}>
          <CrossIconButton onClose={props.onClose} />
        </Box>
      </DialogTitle>
      <DialogContent sx={{minWidth: '320px'}}>
        <Loading in={loading}>
          {initialFormValue &&
          <EducationForm
            hasDelete={!!props.educationId}
            educationApiData={initialFormValue}
            onFormSubmit={(value) => props.onFormSubmit(value)}
            onDelete={props.onDelete}/>
          }
        </Loading>
      </DialogContent>
    </Dialog>

  </Backdrop>
}