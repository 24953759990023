import {Box, Card, Typography} from "@mui/material";
import React from "react";
import {RouteComponentProps} from "react-router";
import { Link } from "react-router-dom";
import {LastScheduledEmails, MigratePositionMatchButton, MigrateStripeAccounts} from "../../../feature/service";
import {GoogleMeetButton} from "../../../feature/service/google-meet-button";
import {MigrateResume} from "../../../feature/service/migrate-resume";

const Page = (props: RouteComponentProps<{}>) => {

  return <div>
    <Card sx={{m: 3}}>
      <Box sx={{mb: 3}}>
        <Typography sx={{fontWeight: 700, fontSize: 18}}>Service page</Typography>
      </Box>
      <Box sx={{my: 3}}>
        <MigrateStripeAccounts />
      </Box>
      <Box sx={{my: 3}}>
        <MigratePositionMatchButton />
      </Box>
      <Box sx={{my: 3}}>
        <GoogleMeetButton />
      </Box>
      <Box sx={{m: 3}}>
        <Link to={"/service/scheduled-emails"}>Last emails</Link>
      </Box>
      <Box sx={{my: 3}}>
        <MigrateResume />
      </Box>
    </Card>
  </div>
}

export default Page
