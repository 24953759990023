import {useFormik} from "formik";
import {Box, Button, FormControl, FormHelperText, FormLabel, MenuItem, Select, TextField} from "@mui/material";
import {EducationFormFields} from "./types";
import {schemaFields} from "./schemaFields";
import {EducationDegree} from "../../../../api/sdl";
import React from "react";
import {readableDegree} from "./utils";
import {
  ConfirmableDeleteButton
} from "../../../../feature/employment-history-editable-list/ui/ConfirmableDeleteButton";
import {getMonthName, monthsRange} from "../../../../feature";

/**
 - degree *
 - school *
 - studyField

 - startDateMonth
 - startDateYear
 - endDateMonth
 - endDateYear
 */


interface Props {
  educationApiData: EducationFormFields
  onFormSubmit: (values: any) => void,
  onDelete: () => void | undefined,
  hasDelete: boolean,
}


export function EducationForm(props: Props) {

  // EducationPayloadInput
  const formik = useFormik<EducationFormFields>({
    initialValues: props.educationApiData as any,
    validationSchema: schemaFields,
    onSubmit: (values) => {
      props.onFormSubmit(values)
    },
    validateOnChange: false,
    enableReinitialize: true,
  });

  return <Box component={'form'} flex={4} onSubmit={formik.handleSubmit}>

    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, mb: 3, flex: 1, maxWidth: 500, mt: {xs: 3, md: 0}}}>

      <FormControl error={formik.touched.school && !!formik.errors.school}>
        <FormLabel>School</FormLabel>
        <TextField placeholder="School"
                   name="school"
                   data-test="school"
                   value={formik.values.school}
                   onChange={formik.handleChange}
                   error={formik.touched.school && Boolean(formik.errors.school)}
        />
        {(formik.touched.school && formik.errors.school) &&
            <FormHelperText data-test="school-error" sx={{color: '#D3232F'}}>{formik.errors.school}</FormHelperText>}
      </FormControl>

      <FormControl sx={{minWidth: 200}} error={formik.touched.degree && !!formik.errors.degree}>
        <FormLabel>Degree</FormLabel>
        <Select data-test="degree" value={formik.values.degree?.toString()} onChange={(e) => {
          if (e.target.value) {
            formik.setFieldValue('degree', e.target.value)
          }
        }}>
          {degrees().map((v, i) => <MenuItem key={'d' + i}
                                                                  value={v}>{readableDegree(v)}</MenuItem>)}
        </Select>
        {(formik.touched.degree && formik.errors.degree) &&
            <FormHelperText data-test="degree-error"
                            sx={{color: '#D3232F'}}>{formik.errors.degree}</FormHelperText>}
      </FormControl>

      <FormControl error={formik.touched.studyField && !!formik.errors.studyField}>
        <FormLabel>Field of Study</FormLabel>
        <TextField placeholder="Field of Study"
                   name="studyField"
                   data-test="studyField"
                   value={formik.values.studyField}
                   onChange={formik.handleChange}
                   error={formik.touched.studyField && Boolean(formik.errors.studyField)}
        />
        {(formik.touched.school && formik.errors.school) &&
            <FormHelperText data-test="study-field-error" sx={{color: '#D3232F'}}>{formik.errors.studyField}</FormHelperText>}
      </FormControl>

      {/* START DATE */}
      <Box sx={{display: 'grid', gridTemplateColumns: {xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}, gap: 3, width: 1, alignItems: 'flex-end'}}>
        <FormControl sx={{ minWidth: 200 }} error={formik.touched.startDateMonth && Boolean(formik.errors.startDateMonth)}>
          <FormLabel>Start date</FormLabel>
          <Select data-test="start-date-month" value={formik.values.startDateMonth?.toString()} onChange={(e) => {
            if (e.target.value) {
              formik.setFieldValue('startDateMonth', e.target.value)
            }
          }}>
            {monthsRange().map((v, i) => <MenuItem key={'st' + v } value={v.toString()}>{getMonthName(v)}</MenuItem>)}
          </Select>
          {(formik.touched.startDateMonth && formik.errors.startDateMonth) && <FormHelperText data-test="start-month-errror" sx={{color: '#D3232F'}}>{formik.errors.startDateMonth}</FormHelperText>}
        </FormControl>
        <FormControl sx={{width: 1}}>
          <TextField placeholder="Year"
                     name="startDateYear"
                     data-test="start-date-year"
                     type={'number'}
                     value={formik.values.startDateYear}
                     onChange={formik.handleChange}
                     error={formik.touched.startDateYear && Boolean(formik.errors.startDateYear)}
          />
          {(formik.touched.startDateYear && formik.errors.startDateYear) && <FormHelperText data-test="start-year-error" sx={{color: '#D3232F'}}>{formik.errors.startDateYear}</FormHelperText>}
        </FormControl>
      </Box>

      {/* END DATE */}
      <Box sx={{display: 'grid', gridTemplateColumns: {xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}, gap: 3, width: 1, alignItems: 'flex-end'}}>
        <FormControl sx={{ minWidth: 200 }} error={formik.touched.endDateMonth && Boolean(formik.errors.endDateMonth)}>
          <FormLabel>End date</FormLabel>
          <Select data-test="end-date-month" value={formik.values.endDateMonth?.toString()} onChange={(e) => {
            if (e.target.value) {
              formik.setFieldValue('endDateMonth', e.target.value)
            }
          }}>
            {monthsRange().map((v, i) => <MenuItem key={'st' + v } value={v.toString()}>{getMonthName(v)}</MenuItem>)}
          </Select>
          {(formik.touched.endDateMonth && formik.errors.endDateMonth) && <FormHelperText data-test="end-month-errror" sx={{color: '#D3232F'}}>{formik.errors.endDateMonth}</FormHelperText>}
        </FormControl>

        <FormControl sx={{width: 1}}>
          <TextField placeholder="Year"
                     name="endDateYear"
                     data-test="end-date-year"
                     type={'number'}
                     value={formik.values.endDateYear}
                     onChange={formik.handleChange}
                     error={formik.touched.endDateYear && Boolean(formik.errors.endDateYear)}
          />
          {(formik.touched.endDateYear && formik.errors.endDateYear) && <FormHelperText data-test="end-year-error" sx={{color: '#D3232F'}}>{formik.errors.endDateYear}</FormHelperText>}
        </FormControl>
      </Box>

      <Box sx={{my:2, display: 'flex'}}>
        <Box sx={{width: 1}}>
          {props.hasDelete &&
              <ConfirmableDeleteButton onConfirm={() => props.onDelete()} title={'Are you sure you want to delete the education item?'} />
          }
        </Box>
        <Box sx={{width: 1, textAlign: "right"}}>
          <Button variant="contained"  data-test="submit-form" onClick={() => {formik.submitForm()}}>Submit</Button>
        </Box>
      </Box>

    </Box>
  </Box>
}

function degrees() {
  return [
    EducationDegree.AssociateDegree,
    EducationDegree.BachelorDegree,
    EducationDegree.MasterDegree,
    EducationDegree.DoctoralDegree,
    EducationDegree.DoctorMedicine,
    EducationDegree.CertificateDiplomaProgram,
  ]
}