import * as yup from "yup";
import {EducationPayloadInput} from "../../../../api/sdl";

/**
- degree *
- school *
- studyField

- startDateMonth
- startDateYear
- endDateMonth
- endDateYear
 */

export const schemaFields = yup.object<EducationPayloadInput>({
    degree: yup.string().required('Degree is required'),
    school: yup.string().required('School is required'),
    studyField: yup.string().required('Field of Study is required'),

    startDateMonth: yup.string().required('Field is required'),
    startDateYear: yup.string().required('Field is required'),
    endDateMonth: yup.string().required('Field is required'),
    endDateYear: yup.string().required('Field is required'),

  }
)