import {Backdrop, Box, Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import EmploymentHistoryForm from "./EmploymentHistoryForm";
import {useEmploymentHistoryLazyQuery, useEmploymentHistoryQuery} from "../../../api/sdl";
import Loading from "../../../shared/components/Loading";
import {EmploymentHistoryData, EmploymentHistoryFormFields, toEmploymentHistory, toFormFields} from "../lib";
import React, {useEffect} from "react";
import {CrossIconButton} from "../../cross-icon-button";
import {useIsMobile} from "../../../shared";

interface IProps {
    employmentHistoryId?: string,
    open: boolean,
    onClose: () => void,
    onDelete: () => void,
    onFormSubmit: (value: EmploymentHistoryData) => void,
}

const initialValues: EmploymentHistoryFormFields = {
    specialities: [] as string[],
    employmentTypes: [] as string[],
    companyName: '',
    currentlyIsWorking: false,
    description: '',
    endDateMonth: '',
    endDateYear: '',
    location: '',
    startDateMonth: '',
    startDateYear: '',
    title: '',
    id: undefined,
}

export const EmploymentHistoryModal = (props: IProps) => {

    const [requestData, {data, loading}] = useEmploymentHistoryLazyQuery({fetchPolicy: "no-cache"})
    const isMobile = useIsMobile();

    const [initialFormValue, setInitialFormValue] = React.useState<EmploymentHistoryFormFields>();

    useEffect(() => {
        if(props.employmentHistoryId) {
            requestData({variables: {employmentHistoryId: props.employmentHistoryId}})
                .then((res) => {
                    setInitialFormValue(toFormFields(res.data!.employmentHistory))
                })
        } else {
            setInitialFormValue(initialValues)
        }
    }, [props.employmentHistoryId]);

    if(!initialFormValue) return null

    return <>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.open}
            onClick={() => {}}
        >
        <Dialog open={props.open} fullScreen={isMobile}>
            <DialogTitle>
                <Box sx={{position: 'absolute', right: 20, zIndex: "10000"}}>
                    <CrossIconButton onClose={props.onClose} />
                </Box>
            </DialogTitle>
            <DialogContent sx={{minWidth: '300px'}}>
                <Loading in={loading}>
                    <EmploymentHistoryForm
                        employmentHistory={initialFormValue}
                        onFormSubmit={(value) => props.onFormSubmit(toEmploymentHistory(value))}
                        onDelete={props.onDelete}/>
                </Loading>
            </DialogContent>
        </Dialog>
        </Backdrop>
    </>
}