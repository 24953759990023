import {DialogTitle, Drawer, Paper} from "@mui/material";
import React from "react";
import {ContractorCard} from "../../../widget";
import {useContractorProfileByIdQuery} from "../../../api/sdl";
import {CrossIconButton} from "../../cross-icon-button";

interface Props {
  contractorId: string,
  onClose: () => void,
}

export function ContractorProfileDrawer(props: Props) {

  const {data} = useContractorProfileByIdQuery({variables: {id: props.contractorId}})
  const profile = data?.contractorProfileById
  const educations = data?.educations

  return <Drawer

  open={true}
  anchor={'right'}>
    <Paper
      sx={{
        maxWidth: 1200,
        minWidth: '80vw',
        borderRadius: 'md',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'auto',
      }}
    >
      <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', position: 'sticky'}}>
        Profile
        <CrossIconButton onClose={props.onClose} />
      </DialogTitle>

      <ContractorCard
        profile={profile?.contractorProfile || undefined}
        userProfile={profile?.profile || undefined}
        showSideStripe={false}
        editable={false}
        educations={educations}
      />

    </Paper>
  </Drawer>
}
