import {ContractorSearchPayloadInput, JointProfileOutput, useContractorSearchTableQuery} from "../../../api/sdl";
import {usePageSize} from "../hooks/usePageSize";
import {usePage} from "../hooks/usePage";
import React, {useEffect, useMemo} from "react";

function convertToApiPayload(filter: ContractorSearchPayloadInput) {
  return {...filter, state: (filter.state === '') ? null : filter.state}
}
export function useContractorSearch(filter: ContractorSearchPayloadInput) {

  const [rowsPerPage, setRowsPerPage] = usePageSize();
  const [page, setPage] = usePage();

  const {loading, data, error, refetch} = useContractorSearchTableQuery({
    fetchPolicy: 'no-cache',
    variables: {
      payload: convertToApiPayload(filter),
      page: {
        size: rowsPerPage,
        page
      }
    },
  });

  useEffect(() => {
    if (data?.contractorSearch.totalPages && data.contractorSearch.totalPages - 1 < page) {
      setPage(data?.contractorSearch.totalPages - 1)
    }
  }, [data?.contractorSearch.totalPages, page]);


  const goNextPage = () => {
    setPage(page + 1 );
  }

  const goPreviousPage = () => {
    setPage( page - 1 )
  }

  useEffect(() => {
    if (data?.contractorSearch.totalPages && data.contractorSearch.totalPages - 1 < page) {
      setPage(data?.contractorSearch.totalPages - 1)
    }
  }, [data?.contractorSearch.totalPages, page]);

  const total = useMemo(() => {
    return data?.contractorSearch.totalElements || 0;
  }, [data?.contractorSearch.totalElements]);

  const rows: Partial<JointProfileOutput>[] = React.useMemo(() => {
    return data?.contractorSearch.data as Partial<JointProfileOutput>[] || [];
  }, [data?.contractorSearch.data])

  return {
    loading,
    goNextPage,
    goPreviousPage,
    rows,
    total,
    error,
    page,
    rowsPerPage,
    isLastPage: data?.contractorSearch?.isLast
  }
}