import {ContractorCardInnerProps, ContractorCardProps} from "../lib/types";
import useCvProfile from "../api/use-cv-profile";
import useIsMobile from "../../../shared/hooks/useIsMobile";
import {useDeleteEmploymentHistoryMutation} from "../../../api/sdl";
import {Box, Divider, Typography} from "@mui/material";
import PersonalSummary from "./personal-summary";
import {EmploymentHistoryEditableList} from "../../../feature";
import Videos from "./Videos";
import {ContractorAwards} from "../../../feature/contractor-awards";
import {Side} from "./Side";
import React, {useState} from "react";
import ContractorCardBottomNav from "../../../contractor/components/ContractorCardBottomNav";
import {EducationEditableList} from "./education-list/EducationEditableList";

export function ContractorCard(props: ContractorCardProps) {
  const cvProfileData = useCvProfile(!!props.editable, props.profile?.contractorId);
  const isMobile = useIsMobile();

  const [deleteEmploymentHistory] = useDeleteEmploymentHistoryMutation()

  const onWorkHistoryUpdate = async () => {
    props.onUpdate && props.onUpdate();
  }

  const onWorkHistoryDelete = (employmentHistoryId: string) => {
    return deleteEmploymentHistory({variables: {id: employmentHistoryId}}).then(() => {
      props.onUpdate && props.onUpdate();
    })
  }

  const name = {
    firstName: props.firstName || props.userProfile?.firstName,
    lastName: props.lastName || props.userProfile?.lastName
  };
  return isMobile
    ? <Mobile {...props} {...name} {...cvProfileData} onWorkHistoryUpdate={onWorkHistoryUpdate} onWorkHistoryDelete={onWorkHistoryDelete} onUpdate={props.onUpdate} />
    : <Desktop {...props} {...name} {...cvProfileData} onWorkHistoryUpdate={onWorkHistoryUpdate} onWorkHistoryDelete={onWorkHistoryDelete} onUpdate={props.onUpdate}/>
}

//-------------

const Desktop = (props: ContractorCardInnerProps) => {
  const {onWorkHistoryDelete, profile, editable, onUpdate, cvProfile, lastName, firstName, onVideoEditClick} = props;
  return <Box sx={{p: {xs: 0, md: 3}, mt: {xs: 7, md: 0}}}>
    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
      <Box sx={{flex: 1, mr: {md: '5vw'}}}>
        <Typography sx={{fontSize: 34, fontWeight: 600, mb: 5}} variant={'h2'}>{firstName} {lastName}</Typography>
        <PersonalSummary personalSummary={profile?.personalSummary}
                         updatableFromCv={!!cvProfile?.contractorProfile?.personalSummary}
                         cvPersonalSummary={cvProfile?.contractorProfile?.personalSummary}
                         editable={editable}
                         onUpdate={onUpdate}
                         contractorId={profile?.contractorId} />
        <Divider sx={{my: 6}} />

        <EmploymentHistoryEditableList histories={profile?.employmentHistory}
                                       updatableFromCv={!!cvProfile?.contractorProfile.employmentHistory}
                                       editable={editable}
                                       contractorId={profile?.contractorId}
                                       onItemDelete={onWorkHistoryDelete}
                                       onUpdate={props.onWorkHistoryUpdate} />
        <Divider sx={{my: 6}} />
        <Videos onVideoEditClick={onVideoEditClick} profile={profile} editable={editable} onEdit={onUpdate} />

        <Divider sx={{my: 6}} />

        <ContractorAwards editable={editable} awards={profile?.awards} />

        <Divider sx={{my: 6}} />

        <EducationEditableList educations={props.educations || []}
                               editable={editable}
                               contractorId={profile?.contractorId}
                               onUpdate={props.onWorkHistoryUpdate}
        />
      </Box>

      <Side {...props} onEdit={onUpdate} />

    </Box>
  </Box>
}

//-------------

const Mobile = (props: ContractorCardInnerProps) => {
  const {onWorkHistoryDelete, profile, editable, onEdit, cvProfile, lastName, firstName, onVideoEditClick} = props;
  const [index, setIndex] = useState(0);

  const onNavClick = (newIndex: number) => {
    setIndex(newIndex);
  }

  return <Box sx={{p: 0, mt: 7}}>
    <Box sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
      <Typography sx={{fontSize: 34, fontWeight: 600, mb: 5}} variant={'h2'}>{firstName} {lastName}</Typography>

      {index === 0 && <>
          <PersonalSummary personalSummary={profile?.personalSummary}
                           updatableFromCv={!!cvProfile?.contractorProfile?.personalSummary}
                           cvPersonalSummary={cvProfile?.contractorProfile?.personalSummary}
                           editable={editable}
                           onEdit={onEdit}
                           contractorId={profile?.contractorId} />
          <Side {...props} />
      </>}
      {index === 1 && <>
        <EmploymentHistoryEditableList histories={profile?.employmentHistory}
                                                     updatableFromCv={!!cvProfile?.contractorProfile.employmentHistory}
                                                     contractorId={profile?.contractorId}
                                                     editable={editable}
                                                     onUpdate={props.onWorkHistoryUpdate}
                                                     onItemDelete={onWorkHistoryDelete}
                                                     onEdit={onEdit} />

        <EducationEditableList educations={props.educations || []}
                               editable={editable}
                               contractorId={profile?.contractorId}
                               onUpdate={props.onWorkHistoryUpdate}
        />
      </>}

      {index === 2 && <Videos onVideoEditClick={onVideoEditClick} profile={profile} editable={editable} onEdit={onEdit} />}

      {index === 3 && <>
          <ContractorAwards editable={editable} awards={profile?.awards}/>

      </> }


      <ContractorCardBottomNav index={index} onChange={onNavClick} />
    </Box>
  </Box>
}


