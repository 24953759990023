import {EmploymentHistoryQuery} from "../../../api/sdl";

export interface EmploymentHistoryFormFields {
  specialities: string[],
  employmentTypes: string[],
  companyName: string,
  currentlyIsWorking: boolean,
  description: string,
  endDateMonth: string,
  endDateYear: string,
  location: string,
  startDateMonth: string,
  startDateYear: string,
  title: string,
  id?: string,
}

export type EmploymentHistoryData = EmploymentHistoryQuery["employmentHistory"]

export enum DisplayMode {
  View,
  Edit,
  UpdateFormPdf
}