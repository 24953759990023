import {
  Box,
  Card,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import DataGrid, {DataGrigColumn} from "../../../shared/components/DataGrid";
import {DateTime} from "luxon";
import {
  InvoiceItem,
  Maybe,
  useFlatInvoiceItemsLazyQuery,
} from "../../../api/sdl";
import HarvestClientSelect from "../../components/HarvestClientSelect";
import HarvestProjectSelect from "../../components/HarvestProjectSelect";
import { toCurrency } from "../../../shared/utils";



const columns: DataGrigColumn<Partial<InvoiceItem>>[]  = [{
  title: 'Created at',
  value: o => <Typography variant={'body2'}>
    {DateTime.fromISO(o.createdAt).toFormat('LL/dd/yyyy')}<br />
    {DateTime.fromISO(o.createdAt).toFormat('hh:mm a')}
  </Typography>
}, {
  title: 'Description',
  value: o => o.description
}, {
  title: 'Amount',
  value: o => toCurrency(o.amount, 2, 2)
}, {
  title: 'InvoiceId',
  value: o => o.invoiceId,
}, {
  title: 'Status',
  value: o => o.status,
}]

export default function InvoiceList() {
  const [clientId, setClientId] = useState<Maybe<string>>(null);

  const [projectId, setProjectId] = useState<Maybe<string>>(null);

  const [items, setItems] = useState<InvoiceItem[]>([]);
  const [getItems, itemsData] = useFlatInvoiceItemsLazyQuery({fetchPolicy: "no-cache"});

  useEffect(() => {
    if (clientId && projectId) {
      setItems([]);
      getItems({variables: {clientId, projectId}})
        .then(res => setItems(res.data?.flatInvoiceItems || []))
    }
  }, [clientId, projectId]);

  return <div>
    <Box>
      <Typography variant={'h3'} sx={{ml: 2}}>Invoices</Typography>
      <Box sx={{display: 'flex', alignItems: 'center', flex: 1, gap: 2, ml: 2, mt: 2, maxWidth: 500}}>
        <HarvestClientSelect value={clientId} onChange={setClientId} />
        <HarvestProjectSelect value={projectId} onChange={setProjectId} clientId={clientId} />
      </Box>
    </Box>
    {itemsData.called && <Card sx={{mt: 3, p: 0}}>
      <DataGrid columns={columns}
                rows={items}
                rowsPerPage={10000}
                page={0}
                hidePaginator
                onNextClick={() => {}}
                onPrevClick={() => {}}
                loading={itemsData.loading}
      />
    </Card>}
  </div>
}
