import {Box, Button, Typography} from "@mui/material";
import * as _ from "lodash";
import React from "react";
import {useHistory} from "react-router-dom";
import {CompanyApiData, UserProfileApiData} from "./lib/types";

interface Props {
  company: CompanyApiData,
  userProfile: UserProfileApiData,
}
export function CompanyCard({company, userProfile}: Props) {
  const history = useHistory();

  return <Box component={'table'} sx={{width: 'fit-content', my: 1, ml: 2}}>
    <tbody>
    <tr>
      <td>
        <Typography sx={{fontWeight: 600}}>Company name:</Typography>
      </td>
      <td>
        <Typography>{company?.companyProfile?.name}</Typography>
      </td>
    </tr>
    <tr>
      <td>
        <Typography sx={{fontWeight: 600}}>Web page:</Typography>
      </td>
      <td>
        <Typography>{company?.companyProfile?.url}</Typography>
      </td>
    </tr>
    <tr>
      <td>
        <Typography sx={{fontWeight: 600, mr: 3}}>Contact name:</Typography>
      </td>
      <td>
        <Typography>{userProfile?.firstName} {userProfile?.lastName}</Typography>
      </td>
    </tr>
    <tr>
      <td>
        <Typography sx={{fontWeight: 600}}>Phone:</Typography>
      </td>
      <td>
        <Typography>{userProfile?.phone}</Typography>
      </td>
    </tr>
    <tr>
      <td>
        <Typography sx={{fontWeight: 600}}>Email:</Typography>
      </td>
      <td>
        <Typography>{userProfile?.email}</Typography>
      </td>
    </tr>
    <tr>
      <td>
        <Typography sx={{fontWeight: 600, mr: 3}}>Number of employees:</Typography>
      </td>
      <td>
        <Typography>{company?.companyProfile?.numberOfEmployees ? _.upperFirst(_.lowerCase(company?.companyProfile?.numberOfEmployees)) : ''}</Typography>
      </td>
    </tr>
    <tr>
      <td>
        <Typography sx={{fontWeight: 600, mr: 3}}>Manager:</Typography>
      </td>
      <td>
        <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
          <Typography>{[company?.accountManager?.firstName, company?.accountManager?.lastName].filter(v => !!v).join(' ') + ` (${company?.accountManager?.email})`}</Typography>
          <Button size={'small'} variant={'outlined'} onClick={() => history.push(`/company/${company.companyProfile.id}/manager`)}>Edit</Button>
        </Box>
      </td>
    </tr>
    </tbody>
  </Box>
}