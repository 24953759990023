import {Box, Button, Card, Chip, Divider, Typography} from "@mui/material";
import React from "react";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {
  PositionOutput,
  PositionStatus,
  usePositionsByCompanyIdQuery,
} from "../../api/sdl";
import DataGrid, {DataGrigColumn} from "../../shared/components/DataGrid";
import Breadcrumbs from "../../shared/components/Breadcrumbs";
import * as _ from 'lodash';
import {CompanyCard} from "../../widget/company-card/CompanyCard";
import {useCompanyApi} from "../../entity/company/api/useCompanyApi";

const columns: DataGrigColumn<Partial<PositionOutput>>[]  = [{
  title: 'Title',
  value: o => o.title || ''
}, {
  title: 'Candidates',
  value: o => (o.numberOfCandidates || 0) + '',
}, {
  title: 'Status',
  value: o => <Chip size={'small'}
                    label={_.startCase(o.status?.toLowerCase())}
                    sx={{background: [PositionStatus.Archived, PositionStatus.Closed, PositionStatus.Deleted].includes(o.status as any) ? '#bf2121' : '#3075b8'}} />,
},
  {
    title: 'Commission',
    value: o => <>{o.platformCommission}%</>,
  }];

export default function PositionListByCompany(props: RouteComponentProps<{id: string}>) {
  const history = useHistory();
  const {company, userProfile} = useCompanyApi(props.match.params.id);

  const {loading, data, error} = usePositionsByCompanyIdQuery({
    fetchPolicy: 'no-cache',
    variables: {companyId: props.match.params.id, page: {page: 0, size: 1000}}
  });

  const rows = React.useMemo(() => {
    return data?.positionsByCompanyId?.data || [];
  }, [data?.positionsByCompanyId])

  return <div>
    <Box sx={{display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'space-between', gap: 2}}>
      <Typography variant={'h3'} sx={{ml: 2}}>{company?.companyProfile?.name ? `${company?.companyProfile?.name} positions` : 'Loading...'} </Typography>
    </Box>
    <Box sx={{mx: 2, my: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Breadcrumbs crumbs={[{title: 'All companies', link: '/companies'}, {title: company?.companyProfile?.name ? `${company?.companyProfile?.name} positions` : '...'}, ]} />
      <Button size={'small'} variant={'contained'} onClick={() => history.push(`/company/${props.match.params.id}/position`)}>Create new</Button>
    </Box>
    <Divider />
    {company && userProfile &&
        <CompanyCard company={company} userProfile={userProfile} />
    }
    <Divider />
    <Card sx={{mt: 1, p: 0}}>
      <DataGrid columns={columns}
                rows={rows}
                rowsPerPage={1000}
                page={0}
                hidePaginator
                onRowClick={row => history.push(`/company/${company?.companyProfile?.id}/position/` + row.id)}
                error={error?.message}
                onNextClick={() => {}}
                onPrevClick={() => {}}
                loading={loading}
                isLastPage={true}
      />
    </Card>
  </div>
}
