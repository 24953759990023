import {Box, CircularProgress, IconButton, Typography, SxProps} from "@mui/material";
import {ArrowDown, CaretLeft, CaretRight} from "phosphor-react";
import React from "react";

interface DataGridProps<T> {
  loading?: boolean,
  columns: DataGrigColumn<T>[];
  rows: T[],
  rowsPerPage: number,
  page: number,
  total?: number,
  onNextClick: () => void,
  onPrevClick: () => void,
  onRowClick?: (row: T) => void,
  isLastPage?: boolean,
  hidePaginator?: boolean,
  error?: string,
}

export default function DataGrid<T>(props: DataGridProps<T>) {
  const {loading, columns, rowsPerPage, page, rows, onNextClick, onPrevClick, isLastPage, onRowClick, error} = props;

  return <Box sx={{position: 'relative', minHeight: 500}}>
    {loading && <Box sx={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: '#ffffffa3', zIndex: 2, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <CircularProgress size="15" />
    </Box>}
    <Box sx={styles} component={'table'} data-test='data-table' cellSpacing={0}>
      <thead>
      <tr>
        {columns.map(c => <td key={c.title}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <Typography>{c.title}</Typography>
            {c.sortable && <ArrowDown size={16} color={isLastPage ? '#aaa' : "#000000"} weight="regular" />}
          </Box>
        </td>)}
      </tr>
      </thead>
      <tbody>
      {rows.map((row, i) => <tr key={'row' + i} onClick={() => onRowClick && onRowClick(row)}>
        {columns.map(c => <td key={'row' + c.title + i}>{c.value(row)}</td>)}
      </tr>)}
      {(!loading && !rows.length) && <tr>
        <td colSpan={columns.length}>
          <Box sx={{display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center', width: 1}}>
            {!error && <Typography  sx={{mt: 23}}>No data available</Typography>}
            {!!error && <Typography  sx={{mt: 23}}>An error occurred while fetching data</Typography>}
          </Box>
        </td>
      </tr>}
      </tbody>
    </Box>
    {(!loading && !!rows.length && !props.hidePaginator) && <Box sx={{display: 'flex', gap: 2, p: 2, borderTop: '1px solid rgb(242, 244, 247)', alignItems: 'center', justifyContent: 'flex-end', width: 1}}>
      <Typography >
        {rowsPerPage * page + 1} - { isLastPage || (props.total && props.total < rowsPerPage) ? props.total : rowsPerPage * page + rowsPerPage} {props.total ? `of ${props.total}` : ''}
      </Typography>
      <IconButton sx={{borderRadius: '50%'}} size={'small'} data-test='prevclick' disabled={page === 0} onClick={onPrevClick}>
        <CaretLeft size={16} color={page === 0 ? '#aaa' : "#000000"} weight="regular" />
      </IconButton>
      <IconButton sx={{borderRadius: '50%'}} size={'small'} data-test='nextclick' disabled={isLastPage} onClick={onNextClick}>
        <CaretRight size={16} color={isLastPage ? '#aaa' : "#000000"} weight="regular" />
      </IconButton>
    </Box>}
  </Box>
}

export interface DataGrigColumn<T> {
  title: string,
  sortable?: boolean,
  value: (v: T) => JSX.Element | string
}

const styles: SxProps = {
  width: 1,
  '& td': {
    p: 2
  },
  '& tbody td': {
    fontSize: 14,
  },
  '& tbody tr': {
    '&:not(:last-child):hover': {
      backgroundColor: '#F8F9FA',
    },
    '&:not(:last-child) td': {
      borderBottom: '1px solid rgb(242, 244, 247)',
    },
  },
  '& thead tr': {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    '& td': {
      // '&:first-child': {
      //   borderTopLeftRadius: '80px'
      // },
      // '&:last-child': {
      //   borderTopRightRadius: '80px'
      // },
      backgroundColor: '#F8F9FA',
      textTransform: 'uppercase',
      fontWeight: 600,
      color: '#2F3746',
      fontSize: 12,
    }
  }
}
