import * as React from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import {Editable} from "../../../../../shared";
import {CityData} from "../../../../../feature/city-selector/lib/types";
import {TownAndState} from "./TownAndState";

interface Props extends Editable {
  linkedInUrl?: string | null,
  email?: string | null,
  phone?: string | null,
  cities?: CityData[],
}

export function ContactInformationView(props: Props) {
  const { editable, onEdit, linkedInUrl, email, phone, cities } = props;

  return <>
    <Box sx={{display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'space-between', width: 1}}>
      <Typography sx={{color: '#8A8C8D', fontWeight: 500}}>Contact information</Typography>
      {editable && <Button size={'small'} variant={'outlined'} onClick={onEdit} data-test={"edit-contact-information-button"}>Edit</Button>}
    </Box>

    <Stack sx={{mt: 3}} spacing={2}>
      {email && <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
          <Box component={'img'} src={`/img/teenyicons/envelope.svg`}  width={15} height={15} />
          <Typography  sx={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{email}</Typography>
      </Box>}
      {cities && cities.map((it, index) => <TownAndState town={it.city} state={it.stateShort} key={index} /> )}

      {phone && <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
          <Box component={'img'} src={`/img/teenyicons/phone.svg`} width={15} height={15} />
          <Typography>{phone}</Typography>
      </Box>}
      {linkedInUrl && <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
          <Box component={'img'} src={`/img/teenyicons/briefcase_gray.svg`}  width={15} height={15} />
          <Box component={'a'}
               sx={{fontSize: 14, color: '#000', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
               target={'_blank'}
               href={linkedInUrl || ''}>{linkedInUrl}</Box>
      </Box>}
    </Stack>
  </>
}
