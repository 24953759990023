import {DateTime} from "luxon";
import {AttributeCategory, EmploymentHistory} from "../../../api/sdl";
import {EmploymentHistoryFormFields} from "./types";
import {scrollToElement} from "../../../shared";
import {FormikErrors} from "formik";

export * from './form-schema'
export * from './types'



export const formatEmploymentHistoryPeriod = ({startDateYear, startDateMonth, endDateYear, endDateMonth, currentlyIsWorking} : Partial<EmploymentHistory>): string => {
  const start = DateTime.local(startDateYear!, startDateMonth!)
  const end = currentlyIsWorking? DateTime.now() : DateTime.local(endDateYear!, endDateMonth!).plus({month: 1})

  const diff = end.diff(start, ['years', 'months']).toObject()
  // if(diff.months == 0)

  function convertUnit(unit: string, value: number) {
    if(value == 1 && unit == 'years') return 'year';
    if(value == 1 && unit == 'months') return 'month';
    else return unit
  }

// Create a human-readable string but filter out any "0" values
  const humanReadableDiff = Object.entries(diff)
    .filter(([unit, value]) => value !== 0)  // Remove units with value 0
    .map(([unit, value]) => `${Math.floor(value)} ${convertUnit(unit, value)}`)  // Format the remaining units
    .join(' and ');

  return humanReadableDiff
}

export const toFormFields = (history: EmploymentHistory): EmploymentHistoryFormFields => ({
  companyName: history.companyName,
  currentlyIsWorking: history.currentlyIsWorking,
  description: history.description,
  endDateMonth: history.endDateMonth?.toString() || '',
  endDateYear: history.endDateYear?.toString() || '',
  id: history.id || undefined,
  location: history.location || '',
  startDateMonth: history.startDateMonth.toString() ||  '',
  startDateYear: history.startDateYear.toString(),
  title: history.title,
  specialities: history.attributesExpanded?.filter(a => a.category === AttributeCategory.CallPointSpeciality).map(a => a.id) || [] as string[],
  employmentTypes: history.attributesExpanded?.filter(a => a.category === AttributeCategory.EmploymentType).map(a => a.id) || [] as string[],
})

export const toEmploymentHistory = (values: EmploymentHistoryFormFields): EmploymentHistory => {
  const { specialities, employmentTypes, ...rest} = values;
  const payload: EmploymentHistory = {
    ...rest,
    attributes: [...specialities, ...employmentTypes],
    startDateMonth: parseInt(rest.startDateMonth),
    startDateYear: parseInt(rest.startDateYear),
    endDateMonth: rest.endDateMonth !== '' ? parseInt(rest.endDateMonth): null,
    endDateYear: parseInt(rest.endDateYear),
  };
  if (!payload.endDateMonth) {
    delete payload.endDateMonth
  }
  if (!payload.endDateYear) {
    delete payload.endDateYear
  }
  delete payload.attributesExpanded

  return payload;
}

export function scrollToFirstError(errors?: FormikErrors<EmploymentHistoryFormFields>) {
  if (errors) {
    scrollToElement(`[name="${Object.keys(errors)[0]}"]`)
  }
}

export const sortByDate = (a: EmploymentHistory, b: EmploymentHistory) => {
  return b.startDateYear + b.startDateMonth / 10 - (a.startDateYear + a.startDateMonth / 10)
}
