import {IconButton} from "@mui/material";
import {X} from "phosphor-react";
import React from "react";

interface Props{
    onClose: () => void
}

export function CrossIconButton(props: Props) {
    return <IconButton
        onClick={props.onClose}>
        <X size={25} />
    </IconButton>
}