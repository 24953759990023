import {RouteComponentProps} from "react-router";
import React from "react";
import {AdminPosition} from "../../../widget";

const AdminPositionPage = (props: RouteComponentProps<{companyId: string, positionId: string}>) => {
  const {companyId, positionId} = props.match.params;

  return <AdminPosition companyId={companyId} positionId={positionId}/>
}

export default AdminPositionPage;
